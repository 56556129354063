import React from "react"
import { useStaticQuery, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Container, Row, Col } from "react-bootstrap"
import {
  useIntl,
  Link,
  FormattedMessage,
  changeLocale,
} from "gatsby-plugin-intl"

// alice carousel
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home-new"
import SEO from "../components/seo"
import ContactUs from "../components/contact-us"

import AboutImg from "../../static/assets/undraw_medical_research_qg4d.svg"
import ConverstaionImg from "../../static/assets/1- speech therapy.png"
import PhoneIconImg from "../../static/assets/phone_icon.png"
import ChildPsyImg from "../../static/assets/1- counselling.png"
import ActivityTrackerImg from "../../static/assets/1- NSP.png"
import WebsiteBanner from "../../static/assets/website_banner.png"
import NSP from "../../static/assets/NSP-logo1.jpg"
import ME from "../../static/assets/jha.png"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    t1: file(relativePath: { eq: "Aspergers.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, srcSetBreakpoints: [200, 50]) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s1: file(relativePath: { eq: "Aspergers.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, srcSetBreakpoints: [200, 50]) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s1zh: file(relativePath: { eq: "Aspergers (chinese).png" }) {
      childImageSharp {
        fluid(maxWidth: 200, srcSetBreakpoints: [200, 50]) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s2: file(relativePath: { eq: "Autism.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s2zh: file(relativePath: { eq: "Autism (chinese).png" }) {
      childImageSharp {
        fluid(maxWidth: 200, srcSetBreakpoints: [200, 50]) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s3: file(relativePath: { eq: "Dyslexia.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s3zh: file(relativePath: { eq: "Dyslexia (chinese).png" }) {
      childImageSharp {
        fluid(maxWidth: 200, srcSetBreakpoints: [200, 50]) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s4: file(relativePath: { eq: "DevDyslexia.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s4zh: file(relativePath: { eq: "DevDyslexia (chinese).png" }) {
      childImageSharp {
        fluid(maxWidth: 200, srcSetBreakpoints: [200, 50]) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s5: file(relativePath: { eq: "Developmental Delay.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s5zh: file(relativePath: { eq: "Developmental Delay (chinese).png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s6: file(relativePath: { eq: "ADHD.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s6zh: file(relativePath: { eq: "ADHD (chinese).png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s7: file(relativePath: { eq: "Voice Disorder.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s7zh: file(relativePath: { eq: "Voice Disorder (chinese).png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s8: file(relativePath: { eq: "Puberphonia.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s8zh: file(relativePath: { eq: "Puberphonia (chinese).png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s9: file(relativePath: { eq: "Aphasia.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s9zh: file(relativePath: { eq: "Aphasia (chinese).png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s10: file(relativePath: { eq: "Swallowing difficulty.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s10zh: file(relativePath: { eq: "Swallowing difficulty (chinese).png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s11: file(relativePath: { eq: "banner_main.png" }) {
      childImageSharp {
        fluid(maxWidth: 2160, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    s11cn: file(relativePath: { eq: "banner_main_cn.png" }) {
      childImageSharp {
        fluid(maxWidth: 2160, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    s11en: file(relativePath: { eq: "banner_main_en.png" }) {
      childImageSharp {
        fluid(maxWidth: 2160, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    s12: file(relativePath: { eq: "aboutus_bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s12cn: file(relativePath: { eq: "aboutus_bg_cn.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s12en: file(relativePath: { eq: "aboutus_bg_en.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    s13: file(relativePath: { eq: "nsp_intro_bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    s13cn: file(relativePath: { eq: "nsp_intro_bg_cn.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    s13en: file(relativePath: { eq: "nsp_intro_bg_en.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    s14: file(relativePath: { eq: "case_explain.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    s14cn: file(relativePath: { eq: "case_explain_cn.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    s14en: file(relativePath: { eq: "case_explain_en.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    s15: file(relativePath: { eq: "jh_resume.png" }) {
      childImageSharp {
        fluid(maxWidth: 720, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    s15en: file(relativePath: { eq: "jh_resume_en.png" }) {
      childImageSharp {
        fluid(maxWidth: 720, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        featuredImage {
          childImageSharp {
            fluid(
              maxWidth: 480
              maxHeight: 380
              quality: 80
              srcSetBreakpoints: [960, 1440]
            ) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
        address1 {
          addrhk
          addrcn
          addren
          tel
        }
        address2 {
          addrhk
          addrcn
          addren
          tel
        }
        officeHours {
          zh1
          zh2
          zh3
          en1
          en2
          en3
        }
      }
    }
  }
`

const IdeaDiv = styled("div")`
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
  border-width: 1px;
  text-align: center;
  padding: 2% 5% 2% 5%;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  margin-bottom: 20px;
`

const IdeaTitle = styled("p")`
  font-weight: 700;
  color: #ff2ea0;
`

const CenterImgWrap = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  padding-bottom: 15px;
  @media only screen and (max-width: 450px) {
    height: 80px;
  }
`

const AboutImgCss = css`
  max-width: 100%;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

const ContactUsImgCss = css`
  text-align: center;
  width: 30px;
  max-width: 100%;
  height: auto;
`

const STherapyDefCss = css`
  background-color: var(--primary-zebra-color);
  color: var(--title-bg-color);
`
const STherapyImgCss = css`
  height: 220px;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  @media only screen and (max-width: 900px) {
    height: 150px;
  }
  @media only screen and (max-width: 450px) {
    height: 120px;
  }
  @media only screen and (max-width: 320px) {
    height: 100px;
  }
`

const TitleBarCss = css`
  background-color: var(--title-bg-color);
  color: var(--bg-color);
  margin: 0;
  padding: 0;
`

const SCard = ({ title, fluid, text }) => {
  return (
    <div>
      <CenterImgWrap>
        <Img
          css={css`
            display: block;
            height: 150px;
            width: 150px;
            @media only screen and (max-width: 450px) {
              height: 80px;
              width: 150px;
            }
          `}
          fluid={fluid}
        />
      </CenterImgWrap>
      {/*
      <div className="font-weight-bold text-center">{title}</div>
      */}
      <br />
      <p>{text}</p>
    </div>
  )
}

const Syndromes = ({ t, data }) => {
  const intl = useIntl()
  const getImgs = () => {
    switch (intl.locale) {
      case "en":
        return {
          s1: data.s1.childImageSharp.fluid,
          s2: data.s2.childImageSharp.fluid,
          s3: data.s3.childImageSharp.fluid,
          s4: data.s4.childImageSharp.fluid,
          s5: data.s5.childImageSharp.fluid,
          s6: data.s6.childImageSharp.fluid,
          s7: data.s7.childImageSharp.fluid,
          s8: data.s8.childImageSharp.fluid,
          s9: data.s9.childImageSharp.fluid,
          s10: data.s10.childImageSharp.fluid,
        }
      default:
        return {
          s1: data.s1zh.childImageSharp.fluid,
          s2: data.s2zh.childImageSharp.fluid,
          s3: data.s3zh.childImageSharp.fluid,
          s4: data.s4zh.childImageSharp.fluid,
          s5: data.s5zh.childImageSharp.fluid,
          s6: data.s6zh.childImageSharp.fluid,
          s7: data.s7zh.childImageSharp.fluid,
          s8: data.s8zh.childImageSharp.fluid,
          s9: data.s9zh.childImageSharp.fluid,
          s10: data.s10zh.childImageSharp.fluid,
        }
    }
  }
  const myImgs = getImgs()
  return (
    <>
      <Container>
        <Row className="pt-5 pb-5">
          <Col>
            <h2 className="font-weight-bold text-center">
              <a className="anchor" id="Syndromes" />
              {t("Syndromes")}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col xs={3} className="px-1">
            <Link to="/aspergers">
              <SCard title={t("Aspergers")} fluid={myImgs.s1} text="" />
            </Link>
          </Col>
          <Col xs={3} className="px-1">
            <Link to="/autism">
              <SCard title={t("Autism")} fluid={myImgs.s2} text="" />
            </Link>
          </Col>
          <Col xs={3} className="px-1">
            <Link to="/dyslexia">
              <SCard title={t("Dyslexia")} fluid={myImgs.s3} text="" />
            </Link>
          </Col>
          <Col xs={3}></Col>
        </Row>
        <Row>
          <Col xs={3}></Col>
          <Col xs={3} className="px-1">
            <Link to="/devdyslexia">
              <SCard title={t("DevDyslexia")} fluid={myImgs.s4} text="" />
            </Link>
          </Col>
          <Col xs={3} className="px-1">
            <Link to="/devdelays">
              <SCard title={t("DevDelays")} fluid={myImgs.s5} text="" />
            </Link>
          </Col>
          <Col xs={3} className="px-1">
            <Link to="/adhd">
              <SCard title={t("ADHD")} fluid={myImgs.s6} text="" />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={3} className="px-1">
            <Link to="/convoicedisorder">
              <SCard title={t("ConVoiceDisorder")} fluid={myImgs.s7} text="" />
            </Link>
          </Col>
          <Col xs={3} className="px-1">
            <Link to="/puberphonia">
              <SCard title={t("Puberphonia")} fluid={myImgs.s8} text="" />
            </Link>
          </Col>
          <Col xs={3} className="px-1">
            <Link to="/aphasia">
              <SCard title={t("Aphasia")} fluid={myImgs.s9} text="" />
            </Link>
          </Col>
          <Col xs={3} className="px-1">
            <Link to="/swallowingdifficulty">
              <SCard
                title={t("SwallowingDifficulty")}
                fluid={myImgs.s10}
                text=""
              />
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  )
}

const ZebraDiv = styled("div")`
  position: relative;
  background-color: var(--primary-zebra-color);
  width: 99.25vw;
  left: calc(-50vw + 50%);
`

const STherapy = ({ t }) => {
  return (
    <ZebraDiv>
      <Container>
        <Row className="pt-5 pb-5">
          <Col>
            <h2 className="font-weight-bold text-center">{t("STherapy")}</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Link to="/therapy/#psy">
              <div className="pb-4 d-flex flex-column">
                <img css={STherapyImgCss} src={ChildPsyImg} alt="" />
                <div className="font-weight-bold text-center">
                  {t("pages.therapy.p&p.title")}
                </div>
              </div>
            </Link>
          </Col>
          <Col xs={4}>
            <Link to="/therapy/#speech">
              <div className="pb-4 d-flex flex-column">
                <img css={STherapyImgCss} src={ConverstaionImg} alt="" />
                <div className="font-weight-bold text-center">
                  {t("SpeechPathology")}
                </div>
              </div>
            </Link>
          </Col>
          <Col xs={4}>
            <Link to="/therapy/#np">
              <div className="pb-4 d-flex flex-column">
                <img css={STherapyImgCss} src={ActivityTrackerImg} alt="" />
                <div className="font-weight-bold text-center">{t("np")}</div>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </ZebraDiv>
  )
}

const STherapyDef = ({ t, data }) => {
  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }
  return (
    <Container className="therapy-container">
      <div className="nsp-intro">
        {locale === "zh-hk" && <Img fluid={data.s13.childImageSharp.fluid} />}
        {locale === "zh-cn" && <Img fluid={data.s13cn.childImageSharp.fluid} />}
        {locale === "en" && <Img fluid={data.s13en.childImageSharp.fluid} />}
        <div className="text">
          <p>
            {t("pages.index.nsp.desc1")}
            <Link to="/np">{tt("pages.index.nsp.link1")}</Link>
            {t("pages.index.nsp.desc2")}
          </p>
        </div>
      </div>
    </Container>
  )
}

const styles = {
  xx: {
    display: "block",
    width: "100%",
    height: "100%",
  },
  r0: {
    margin: "0px",
  },
  r1: {
    paddingLeft: "10px",
    marginLeft: "0px",
    width: "100%",
  },
}

const AboutMe = ({ t, data }) => {
  const intl = useIntl()
  const locale = intl.locale
  return (
    <Container className="aboutme-container">
      <Row className="pl-2">
        <Col sm={3} xs={12}>
          <div className="profile-image">
            {locale === "en" && (
              <Img style={styles.xx} fluid={data.s15en.childImageSharp.fluid} />
            )}
            {locale !== "en" && (
              <Img style={styles.xx} fluid={data.s15.childImageSharp.fluid} />
            )}
          </div>
        </Col>
        <Col sm={9} xs={12} style={styles.r1}>
          <h3 className="title1">{t("pages.index.aboutme.title1")}</h3>
          <h3 className="title2">{t("pages.index.aboutme.title2")}</h3>
          <div className="mt-4">
            <h4>{t("pages.index.aboutme.qualification1_title")}</h4>
            <p>{t("pages.index.aboutme.qualification1_1")}</p>
            <p>{t("pages.index.aboutme.qualification1_2")}</p>
            <p>{t("pages.index.aboutme.qualification1_3")}</p>
            <p>{t("pages.index.aboutme.qualification1_4")}</p>
            <p>{t("pages.index.aboutme.qualification1_5")}</p>
          </div>
          <div className="mt-4">
            <h4>{t("pages.index.aboutme.qualification2_title")}</h4>
            <p>{t("pages.index.aboutme.qualification2_1")}</p>
            <p>{t("pages.index.aboutme.qualification2_2")}</p>
            <p>{t("pages.index.aboutme.qualification2_3")}</p>
            <p>{t("pages.index.aboutme.qualification2_4")}</p>
            <p>{t("pages.index.aboutme.qualification2_5")}</p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

/*
const HomePage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const intl = useIntl()
  const tt = key => {
    return intl.formatMessage({ id: key })
  }

  const handleDragStart = e => e.preventDefault()
  const getImgThumbnailList = images => {
    return images.map((img, i) => {
      return (
        <img
          key={img.id}
          style={{
            width: "260px",
            height: "230px",
            paddingTop: "10px:",
            paddingRight: "10px",
          }}
          src={img}
          onDragStart={handleDragStart}
          onClick={() => {}}
        />
      )
    })
  }
  const imgThumbList = getImgThumbnailList([
    ConverstaionImg,
    AboutImg,
    ChildPsyImg,
    ActivityTrackerImg,
  ])
  console.log(frontmatter)

  return (
    <Layout>
      <SEO />
      <Container className="home-banner">
        <Row className="justify-content-md-center px-5">
          <Col>
            <div>
              <h1 className="title">{frontmatter.title}</h1>
              <p className="tagline">{frontmatter.tagline}</p>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <Link className="button mb-4" to={frontmatter.cta1.ctaLink}>
                {frontmatter.cta1.ctaText}
              </Link>
              <Link className="button mb-4" to={frontmatter.cta2.ctaLink}>
                {frontmatter.cta2.ctaText}
              </Link>
            </div>
          </Col>
          <Col lg={4} xl={4} md={5} sm={{ offset: 1, span: 10 }} xs={12}>
            <div style={{ width: "100%", margin: "0 auto", marginTop: "20px" }}>
              <AliceCarousel
                disableButtonsControls={true}
                disableDotsControls={true}
                autoPlay={true}
                infinite={true}
                autoPlayInterval={4000}
              >
                {imgThumbList}
              </AliceCarousel>
            </div>
          </Col>
        </Row>
      </Container>
      <STherapyDef t={tt} />
      <STherapy t={tt} />
      <Syndromes t={tt} data={data} />
      <ZebraDiv>
        <div className="container">
          <BlogListHome />
        </div>
      </ZebraDiv>
      <ContactUs t={tt} data={data} />
    </Layout>
  )
}*/

const HomePageNew = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }
  console.log(frontmatter)
  return (
    <Layout>
      <SEO />
      <Row style={styles.r0}>
        <div className="website-banner">
          {locale === "zh-hk" && <Img fluid={data.s11.childImageSharp.fluid} />}
          {locale === "zh-cn" && (
            <Img fluid={data.s11cn.childImageSharp.fluid} />
          )}
          {locale === "en" && <Img fluid={data.s11en.childImageSharp.fluid} />}
        </div>
      </Row>
      <Container>
        <div className="about-us">
          {locale === "zh-hk" && <Img fluid={data.s12.childImageSharp.fluid} />}
          {locale === "zh-cn" && (
            <Img fluid={data.s12cn.childImageSharp.fluid} />
          )}
          {locale === "en" && <Img fluid={data.s12en.childImageSharp.fluid} />}
          <div className="text">
            <p>{tt("pages.index.aboutus")}</p>
          </div>
        </div>
      </Container>
      <AboutMe t={tt} data={data} />
      <STherapyDef t={tt} data={data} />
      <Container className="case-explain" id="Syndromes">
        {locale === "zh-hk" && <Img fluid={data.s14.childImageSharp.fluid} />}
        {locale === "zh-cn" && <Img fluid={data.s14cn.childImageSharp.fluid} />}
        {locale === "en" && <Img fluid={data.s14en.childImageSharp.fluid} />}
        <Link className="button-1" to="/adhd"></Link>
        <Link className="button-2" to="/devdyslexia"></Link>
        <Link className="button-3" to="/aspergers"></Link>
        <Link className="button-4" to="/dyslexia"></Link>
        <Link className="button-5" to="/autism"></Link>
        <Link className="button-6" to="/devdelays"></Link>
        <Link className="button-7" to="/convoicedisorder"></Link>
        <Link className="button-8" to="/puberphonia"></Link>
        <Link className="button-9" to="/swallowingdifficulty"></Link>
        <Link className="button-10" to="/aphasia"></Link>
      </Container>
      <Container>
        <BlogListHome d={data} />
      </Container>
      <ContactUs t={tt} data={data} />
    </Layout>
  )
}

export default HomePageNew
